import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'
import ParseContent from 'components/shared/ParseContent'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkPosts,
} from '@ubo/blog-bink'

// Elements
import ButtonSecondaryTransparent from 'components/elements/Buttons/ButtonSecondaryTransparent'

interface MembersProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Members: React.FC<MembersProps> = ({ fields }) => {
  const {
    allWpMember,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.membersQueryQuery>(graphql`
    query membersQuery {
      allWpMember(sort: { order: ASC, fields: date }) {
        edges {
          node {
            id
            title
            uri
            memberdetail {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, width: 400)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const posts: unknown = allWpMember.edges

  return (
    <section className="mb-5 pb-5" id={fields.sectionid}>
      <BlogBink
        posts={posts as BlogBinkPosts}
        id="members"
        limit={Number(fields.limit)}
      >
        <div className="py-5">
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

const PostBanner = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
`

const Image = styled(Plaatjie)`
  @media (min-width: 1199px) {
    width: 330px;
    height: 445px;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: ${({ theme }) => theme.font.size.giga};
  }
  & p {
    font-size: ${({ theme }) => theme.font.size.huge};
  }
`

const PostsContainer = styled.div`
  @media (min-width: 992px) {
    margin-top: -6rem;
  }
  @media (max-width: 991px) {
    margin-top: -2rem;
  }
`

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const [isHover, setHover] = useState<number | null>(null)

  const blogBink = useBlogBink()

  return (
    <>
      <PostBanner className="px-lg-5 pb-lg-5 p-3">
        <div className="px-lg-5 pb-lg-5">
          <div className="py-5">
            <Content content={fields.description} className="pb-3" />
          </div>
        </div>
      </PostBanner>
      <PostsContainer className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="row">
              {blogBink.posts.map((post, index) => {
                const hovered: boolean = index === isHover

                const { node }: any = post

                return (
                  <div
                    key={post.node.id}
                    className="col-sm-4 col-6 mb-5 d-flex justify-content-center"
                    onMouseEnter={() => setHover(index)}
                    onMouseLeave={() => setHover(null)}
                  >
                    <BlogGridPost
                      node={node}
                      current={hovered}
                      blogFields={fields}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        {blogBink.showMoreButton && (
          <div className="mt-5 text-center">
            <ButtonSecondaryTransparent to="/" isCustom>
              <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
            </ButtonSecondaryTransparent>
          </div>
        )}
      </PostsContainer>
    </>
  )
}

const PostWrapper = styled.div`
  & h2 {
    color: ${({ theme }) => theme.color.light};
    font-size: ${({ theme }) => theme.font.size.post};
  }

  & span {
    color: ${({ theme }) => theme.color.secondary};
    font-size: ${({ theme }) => theme.font.size.big};
  }
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: {
    id: string
    title: string
    uri: string
    memberdetail: {
      image: any
    }
  }
  current: boolean
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => (
  <PostWrapper>
    <motion.div className="position-relative">
      <Image image={node.memberdetail.image} alt="" className="" />
    </motion.div>
  </PostWrapper>
)

export default Members
