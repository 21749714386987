import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimaryBig'
import ButtonSecondaryTransparent from 'components/elements/Buttons/ButtonSecondaryTransparent'
import ParseContent from 'components/shared/ParseContent'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkFilter,
  BlogBinkPosts,
} from '@ubo/blog-bink'
import FlexContentHandler from 'components/shared/FlexContentHandler'
import FormProfessionals from 'components/flex/Form/FormProfessionals'
import BlockDefault from 'components/elements/Blocks/BlockDefault'

// Images
import CircleCheck from 'img/circlecheck.inline.svg'
import Trencher from 'img/trencher.inline.svg'
import Gear from 'img/gear.inline.svg'

const Wrapper = styled.section`
  margin-top: -12.5rem;
  position: relative;
  z-index: 3;

  & .detail {
    font-size: 14px;
  }
`

interface ProfessionalsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  location?: any
}

const Professionals: React.FC<ProfessionalsProps> = ({ fields, location }) => {
  const {
    allWpProfessional,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.professionalsQueryQuery>(graphql`
    query professionalsQuery {
      allWpProfessional(sort: { order: ASC, fields: date }) {
        edges {
          node {
            id
            title
            uri
            professionaldetail {
              description
              education
              experience
              skills {
                skill
              }
              skillsseparator
            }
          }
        }
      }
    }
  `)

  const posts: unknown = allWpProfessional.edges

  return (
    <Wrapper className="mb-5 pb-5">
      <BlogBink
        posts={posts as BlogBinkPosts}
        id="professionals"
        limit={Number(fields.limit)}
      >
        <div className="py-5">
          <BlogFilters />
          <BlogGrid fields={fields} location={location} />
        </div>
      </BlogBink>
    </Wrapper>
  )
}

const StyledBlogFilter = styled(BlogBinkFilter)<{ selected: boolean }>`
  font-weight: ${(props) =>
    props.selected
      ? props.theme.font.weight.bold
      : props.theme.font.weight.light};
`

interface BlogFiltersProps {}

interface BlogFilterProps {
  node: {
    id: string
    name: string
  }
}

const BlogFilters: React.FC<BlogFiltersProps> = () => {
  const blogBink = useBlogBink()

  return (
    <div className="container d-flex">
      {blogBink.categories.map((category: BlogFilterProps) => (
        <StyledBlogFilter
          key={category.node.id}
          id={category.node.id}
          selected={blogBink.isSelected(category.node.id)}
          className="mr-3"
        >
          {category.node.name}
        </StyledBlogFilter>
      ))}
    </div>
  )
}

const PostWrapper = styled.div`
  & svg {
    min-width: 25px;
    min-height: 25px;
    margin-right: 0.5rem;
    & path {
      fill: ${({ theme }) => theme.color.primary};
    }
  }
`

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  location?: any
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields, location }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-9">
          <div className="row mx-0">
            {blogBink.posts.map((post, index: number) => {
              const { node }: any = post

              if (index === 0) {
                return (
                  <>
                    <PostWrapper
                      key={blogBink.posts[0].node.id}
                      className="col-12 mt-5"
                    >
                      <BlogGridPost
                        node={blogBink.posts[0].node}
                        blogFields={fields}
                      />
                    </PostWrapper>
                    <FlexContentHandler
                      prefix="Fragment_FlexFragment"
                      fields={fields?.secondelement?.flex_fragment}
                      location={location}
                    />
                  </>
                )
              }

              return (
                <PostWrapper key={post.node.id} className="col-12 mt-5">
                  <BlogGridPost node={node} blogFields={fields} />
                </PostWrapper>
              )
            })}
          </div>
        </div>
      </div>

      {blogBink.showMoreButton && (
        <div className="mt-5 pt-5 text-center">
          <ButtonSecondaryTransparent to="/" isCustom>
            <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
          </ButtonSecondaryTransparent>
        </div>
      )}
    </div>
  )
}

const SvgWrapper = styled.div`
  min-width: 40px;
`

const Content = styled(ParseContent)`
  & p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: any
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node, blogFields }) => {
  const [popupOpen, setPopupOpen] = useState<boolean>(false)

  const { professionaldetail } = node

  const seperator =
    professionaldetail.skillsseparator === 'semicolon' ? ';' : ','

  return (
    <>
      <BlockDefault
        noBorder
        noHoverEffect
        className="row flex-row align-items-start p-md-5"
      >
        <div className="col-md-4">
          <div className="d-flex flex-column mb-4">
            <h5 className="mb-4 mb-md-3">{node.title}</h5>
            <div className="d-flex">
              <SvgWrapper>
                <CircleCheck />
              </SvgWrapper>
              <p className="detail">{`${professionaldetail.experience} jaar ervaring`}</p>
            </div>
            <div className="d-flex my-2">
              <SvgWrapper>
                <Trencher />
              </SvgWrapper>
              <p className="detail">{professionaldetail.education}</p>
            </div>
            <div className="d-flex">
              <SvgWrapper>
                <Gear />
              </SvgWrapper>
              <p className="detail">
                {professionaldetail.skills?.map((skill: any, index: number) => (
                  <React.Fragment
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >{`${skill?.skill}${seperator} `}</React.Fragment>
                ))}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-8 mb-4">
          <Content
            content={professionaldetail.description}
            className="ps-lg-5"
          />
          <div className="d-flex justify-content-end mt-4">
            <ButtonPrimary to="/" isCustom>
              <button onClick={() => setPopupOpen(true)} type="button">
                {blogFields.readmoretext}
              </button>
            </ButtonPrimary>
          </div>
        </div>
      </BlockDefault>
      <FormProfessionals
        isOpen={popupOpen}
        title={node.title}
        requestClose={() => setPopupOpen(false)}
        blogFields={blogFields}
      />
    </>
  )
}

export default Professionals
