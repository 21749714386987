import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { motion } from 'framer-motion'

// Elements
import ButtonSecondaryTransparent from 'components/elements/Buttons/ButtonSecondaryTransparent'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkPosts,
} from '@ubo/blog-bink'
import Plaatjie from '@ubo/plaatjie'
import NavLink from 'components/shared/NavLink'

// Images
import ArrowRight from 'img/arrowright.inline.svg'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Blog: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpPost,
    allWpCategory,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.blogQueryQuery>(graphql`
    query blogQuery {
      allWpPost(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
      allWpCategory(filter: { name: { nin: "Uncategorized" } }) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges

  return (
    <section className="mb-5 pb-5">
      <BlogBink
        posts={posts as BlogBinkPosts}
        categories={allWpCategory.edges}
        id="posts"
        limit={Number(fields.limit)}
      >
        <div className="py-5 mb-5">
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

const StyledButtonSecondaryTransparent = styled(ButtonSecondaryTransparent)`
  & button {
    font-weight: ${({ theme }) => theme.font.weight.bold} !important;
  }
`

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const [isHover, setHover] = useState<number | null>(null)

  const blogBink = useBlogBink()

  return (
    <div className="container">
      <div className="row">
        {blogBink.posts.map((post, index) => {
          const hovered: boolean = index === isHover

          const { node }: any = post

          return (
            <div
              key={post.node.id}
              className="col-lg-4 col-sm-6 mb-lg-5"
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(null)}
            >
              <BlogGridPost node={node} current={hovered} blogFields={fields} />
            </div>
          )
        })}
      </div>

      {blogBink.showMoreButton && (
        <div className="text-center mt-5 mt-lg-0">
          <StyledButtonSecondaryTransparent to="/" isCustom>
            <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
          </StyledButtonSecondaryTransparent>
        </div>
      )}
    </div>
  )
}

const PostWrapper = styled.div`
  @media (min-width: 1300px) {
    min-height: 530px;
  }
  box-shadow: 1px 1px 12px 0px #404040;
  background-color: ${({ theme }) => theme.color.primary};

  & h2 {
    color: ${({ theme }) => theme.color.light};
    font-size: ${({ theme }) => theme.font.size.post};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    @media (max-width: 575px) {
      font-size: ${({ theme }) => theme.font.size.small};
    }
  }

  & span {
    color: ${({ theme }) => theme.color.secondary};
    font-size: ${({ theme }) => theme.font.size.big};
  }

  & svg {
    width: 20px;
    height: 20px;
    & path {
      fill: ${({ theme }) => theme.color.secondary};
    }
  }
`

const Image = styled(Plaatjie)`
  width: 100%;
  @media (min-width: 1300px) {
    min-height: 344px;
    max-height: 344px;
    object-fit: cover;
  }
  @media (max-width: 575px) {
    max-height: 300px;
  }
`

const Gradient = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  background: linear-gradient(
    0deg,
    rgba(18, 21, 95, 1) 0%,
    rgba(18, 21, 95, 0) 62%
  );
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: {
    id: string
    title: string
    uri: string
    recap: {
      image: any
      excerpt: string
    }
  }
  current: boolean
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node, current }) => (
  <PostWrapper className="m-3">
    <NavLink to={node.uri}>
      <div style={{ overflow: 'hidden' }}>
        <motion.div
          className="position-relative"
          whileHover={{ opacity: 0.75, scale: 1.05 }}
        >
          <Image image={node.recap.image} alt="" />
          <Gradient />
        </motion.div>
      </div>
      <div className="p-lg-5 p-3">
        <h2>{node.title}</h2>
        <NavLink to={node.uri} className="d-flex justify-content-sm-end">
          <span className="me-3">Lees meer</span>
          <motion.span
            animate={current ? { x: 20 } : { x: 0 }}
            transition={{ type: 'spring', stiffness: 100 }}
          >
            <ArrowRight />
          </motion.span>
        </NavLink>
      </div>
    </NavLink>
  </PostWrapper>
)

export default Blog
