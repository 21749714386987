/* eslint-disable no-nested-ternary */
import React from 'react'
import styled, { css } from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimaryBig'
import ButtonSecondaryTransparent from 'components/elements/Buttons/ButtonSecondaryTransparent'
import ParseContent from 'components/shared/ParseContent'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkFilter,
  BlogBinkPosts,
} from '@ubo/blog-bink'

import BlockDefault from 'components/elements/Blocks/BlockDefault'

// Images

import Plaatjie from '@ubo/plaatjie'

const Wrapper = styled.section`
  margin-top: -12.5rem;
  position: relative;
  z-index: 3;

  & .detail {
    font-size: 14px;
  }
`

interface VacanciesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Vacancies: React.FC<VacanciesProps> = ({ fields }) => {
  const {
    allWpVacancy,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.vacanciesQueryQuery>(graphql`
    query vacanciesQuery {
      allWpVacancy(sort: { order: DESC, fields: date }) {
        edges {
          node {
            databaseId
            id
            title
            uri
            vacancydetail {
              info {
                icons {
                  iconcontrast {
                    localFile {
                      publicURL
                    }
                  }
                  iconblank {
                    localFile {
                      publicURL
                    }
                  }
                }

                title
              }
              shortdescription
            }
          }
        }
      }
    }
  `)

  const posts: unknown = allWpVacancy.edges
  
  return (
    <Wrapper className="mb-5 pb-5">
      <BlogBink
        posts={posts as BlogBinkPosts}
        id="vacancies"
        limit={Number(fields.limit)}
      >
        <div className="py-5">
          <BlogFilters />
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </Wrapper>
  )
}

const StyledBlogFilter = styled(BlogBinkFilter)<{ selected: boolean }>`
  font-weight: ${(props) =>
    props.selected
      ? props.theme.font.weight.bold
      : props.theme.font.weight.light};
`

interface BlogFiltersProps {}

interface BlogFilterProps {
  node: {
    id: string
    name: string
  }
}

const BlogFilters: React.FC<BlogFiltersProps> = () => {
  const blogBink = useBlogBink()

  return (
    <div className="container d-flex">
      {blogBink.categories.map((category: BlogFilterProps) => (
        <StyledBlogFilter
          key={category.node.id}
          id={category.node.id}
          selected={blogBink.isSelected(category.node.id)}
          className="mr-3"
        >
          {category.node.name}
        </StyledBlogFilter>
      ))}
    </div>
  )
}

const PostWrapper = styled.div`
  & svg {
    min-width: 25px;
    min-height: 25px;
    margin-right: 0.5rem;
    & path {
      fill: ${({ theme }) => theme.color.primary};
    }
  }
`

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  const openVacancy = blogBink.unfilteredPosts.find(
    (_post) => _post.node.databaseId === 1067
  )

  const salaryVacancy = blogBink.unfilteredPosts.find(
    (_post) => _post.node.databaseId === 1927
  )

  const filteredPost = blogBink.posts.filter((post) => post.node.databaseId !== 1067 && post.node.databaseId !== 1927);  
  
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-9">
          <div className="row mx-0">
            {filteredPost.map((post, index: number) => {
                const { node }: any = post                

                if (index === 0) {
                  return (
                    <>
                      <PostWrapper
                        key={filteredPost[0].node.id}
                        className="col-12 mt-5"
                      >
                        <BlogGridPost
                          node={filteredPost[0].node}
                          blogFields={fields}
                        />
                      </PostWrapper>
                      <PostWrapper
                        key={filteredPost[3].node.id}
                        className="col-12 mt-5"
                      >
                        <BlogGridPost
                          node={salaryVacancy?.node}
                          blogFields={fields}
                          isOpenSollicitation
                          customButton
                        />
                      </PostWrapper>
                    </>
                  )
                }

                if (index === 2) {
                  return (
                    <>
                      <PostWrapper
                        key={filteredPost[2].node.id}
                        className="col-12 mt-5"
                      >
                        <BlogGridPost
                          node={filteredPost[2].node}
                          blogFields={fields}
                        />
                      </PostWrapper>
                      <PostWrapper
                        key={filteredPost[3].node.id}
                        className="col-12 mt-5"
                      >
                        <BlogGridPost
                          node={openVacancy?.node}
                          blogFields={fields}
                          isOpenSollicitation
                        />
                      </PostWrapper>
                    </>
                  )
                }

                return (
                  <PostWrapper key={post.node.id} className="col-12 mt-5">
                    <BlogGridPost node={node} blogFields={fields} />
                  </PostWrapper>
                )
              })}
          </div>
        </div>
      </div>

      {blogBink.showMoreButton && (
        <div className="mt-5 pt-5 text-center">
          <ButtonSecondaryTransparent to="/" isCustom>
            <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
          </ButtonSecondaryTransparent>
        </div>
      )}
    </div>
  )
}

const SvgWrapper = styled.div`
  min-width: 40px;
`

const Content = styled(ParseContent)`
  & p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
  }

  & p,
  & span {
    font-weight: ${({ theme }) => theme.font.weight.regular} !important;
  }
`

const StyledBlockDefault = styled(BlockDefault)<{ hasBgColor: boolean }>`
  ${(props) =>
    props.hasBgColor &&
    css`
      background-color: ${({ theme }) => theme.color.primary};
      color: ${({ theme }) => theme.color.light} !important;
    `}
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: any
  isOpenSollicitation?: boolean
  customButton?: boolean
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({
  node,
  blogFields,
  isOpenSollicitation,
  customButton,
}) => (
  <>
    <StyledBlockDefault
      noBorder
      noHoverEffect
      className="row flex-row align-items-start p-md-5"
      hasBgColor={isOpenSollicitation || false}
    >
      <div className="col-md-4">
        <div className="d-flex flex-column mb-4">
          <h5 className="mb-4 mb-md-3">{node?.title}</h5>
          {node?.vacancydetail.info.map((item: any, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="d-flex mb-3" key={index}>
              <SvgWrapper>
                <Plaatjie
                  image={
                    isOpenSollicitation
                      ? item.icons.iconblank
                      : item.icons.iconcontrast
                  }
                  alt={item.title}
                />
              </SvgWrapper>
              {item.title}
            </div>
          ))}
        </div>
      </div>
      <div className="col-md-8 mb-4">
        <Content
          content={node?.vacancydetail.shortdescription}
          className="ps-lg-5"
        />
        <div className="d-flex justify-content-end mt-4">
          <ButtonPrimary to={`${!customButton ? node?.uri : '/salarischeck'}`}>
            {node?.title.toLowerCase().includes('wil jij weten wat je waard bent') ? (
              'Doe de salarischeck'
            ) : node?.title.toLowerCase().includes('open sollicitatie') ? (
              'Lees meer'
            ) : (
              <span>{blogFields.readmoretext}</span>
            )}
          </ButtonPrimary>
        </div>
      </div>
    </StyledBlockDefault>
  </>
)

export default Vacancies
